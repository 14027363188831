import React from 'react'
import ContactButton from './contactButton/contactButton'
import "./contactGroup.css"

class ContactGroup extends React.Component {

    state = {
        posts: []
    }

    render() {
        return (
            
            <section>
                <ul className="ContactGroup">
                    <ContactButton 
                        url="https://www.linkedin.com/in/ryanabrooks/"
                        class="contact"
                        destination="LinkedIn"
                        copy="Work Experience"/>
                    <ContactButton 
                        url="https://medium.com/@RyanABrooks"
                        class="contact"
                        destination="Medium"
                        copy="My Thoughts"/>
                    <ContactButton 
                        url="https://dribbble.com/brooks4"
                        class="contact"
                        destination="Dribbble"
                        copy="Concepts and Playing Around"/>
                    <ContactButton 
                        url="https://twitter.com/RyanABrooks"
                        class="contact"
                        destination="Twitter"
                        copy="Random Thoughts"/>
                    <ContactButton 
                        url="https://www.instagram.com/ryanabrooks/"
                        class="contact"
                        destination="Instagram"
                        copy="Food and Personality"/>
                    <ContactButton 
                        url="mailto:ryan.brooks.jdp@gmail.com?subject=Ryan B. Designs Inquiry"
                        class="primary"
                        destination="Email"
                        copy="Ryan.Brooks.jdp@gmail.com"/>
                </ul>
            </section>
        )
    }

}

export default ContactGroup