import React from "react"
import ContactGroup from "../components/contactGroup/contactGroup"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <div className="ContactPage">
      <div className="HeroGroup ContactHeroGroup">
        <div>
          <h1>Get in Touch!</h1>
          <ContactGroup />
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPage
